<template>
  <keep-alive>
    <component
      :is="current"
      v-bind="params"
      @edit="onEdit"
      @new="onNew"
      @close="backToList"
    />
  </keep-alive>
</template>

<script>
import shipmentList from '@/views/dashboard/components/shipment/shipmentList';
import shipment from '@/views/dashboard/components/shipment/shipmentCard';

export default {
  components: {},
  data: function() {
    return {
      current: shipmentList,
      params: {},
      defaultItem: {
        client: null,
        address_id: null
      }
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$root.$on('shipment_updated', this.onShipmentUpdated);
    this.$root.$on('shipment_added', this.onShipmentAdded);
  },
  beforeDestroy() {
    this.$root.$off('shipment_updated', this.onShipmentUpdated);
    this.$root.$off('shipment_added', this.onShipmentAdded);
  },
  methods: {
    onNew() {
      this.params = {
        value: {
          status: 'in preparation',
          shipping_date: new Date(),
          delivery_date: new Date(),
          ...this.defaultItem
        }
      };
      this.current = shipment;
    },
    onEdit(item) {
      this.params = {
        value: item
      };
      this.current = shipment;
    },
    backToList() {
      this.current = shipmentList;
      this.params = {};
    },
    onShipmentUpdated(shipment) {
      this.params.value = Object.assign({}, shipment);
    },
    onShipmentAdded(shipment) {
      this.params.value = Object.assign({}, shipment);
    }
  }
};
</script>

<style></style>
