<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <template #heading>
      <span class="headline">{{ title }}</span>
    </template>

    <v-tabs v-model="currentTab" fixed-tabs>
      <v-tab
        v-for="item in tabs"
        :key="item.key"
        :href="'#tab-' + item.key"
        @click="onTab(item.key)"
      >
        {{ tabTitle(item) }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item value="tab-shipment">
        <v-card-text>
          <shipment-form
            ref="form"
            :value="item"
            :clients="clients"
            @close="$emit('close')"
            @save="onSave"
          />
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="tab-parcels">
        <shipment-parcels v-if="parcelTabContent == 'list'" :shipment="value" />
      </v-tab-item>
      <v-tab-item value="tab-attachments">
        <shipment-attachments :shipment="value" />
      </v-tab-item>
    </v-tabs-items>
    <save-first :dialog="saveFirstDialog" @close="goBackAndSave()" />
  </v-card>
</template>

<script>
import CustomerService from '@/services/CustomerService.js';

export default {
  components: {
    shipmentParcels: () => import('./shipmentParcels.vue'),
    shipmentForm: () => import('./shipmentForm.vue'),
    shipmentAttachments: () => import('./shipmentAttachments.vue'),
    saveFirst: () => import('./saveFirst.vue')
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      currentTab: 'tab-shipment',
      parcelTabContent: 'list',
      saveFirstDialog: false,

      item: Object.assign({}, this.value),
      clients: []
    };
  },
  computed: {
    isNew() {
      return !this.item?.id;
    },
    title() {
      if (this.isNew) {
        return 'New Shipment';
      }
      return `Shipment #${this.item.id}`;
    },
    tabs() {
      return [
        { key: 'shipment', name: this.$t('shipments.shipment') },
        { key: 'parcels', name: this.$t('shipments.parcels') },
        { key: 'attachments', name: this.$t('attachments.title') }
      ];
    }
  },
  watch: {
    value: {
      handler(val) {
        Object.assign(this.item, val);
      },
      deep: true
    }
  },
  async activated() {
    console.log('activated');
    await this.init();
  },
  methods: {
    async loadCustomers() {
      console.log('Loading customers');

      this.clients = await CustomerService.getCustomers(['Client']);
    },

    async init() {
      this.item = Object.assign({}, this.value);
      console.log('init(); item:', this.item);
      this.currentTab = 'tab-shipment';
      try {
        await Promise.all([this.loadCustomers()]);
      } catch (err) {
        this.showError(this, err);
      }
    },
    tabTitle(tab) {
      if (tab.key == 'parcels') {
        return `${tab.name} - [${this.item.parcel_count || 0}]`;
      }
      return tab.name;
    },
    onTab(id) {
      console.log('onTab:', id);
      if (id != 'Shipment' && this.saveRequired()) {
        this.saveFirstDialog = true;
      }
      if (!this.$refs.form.isAddressSelected()) {
        this.$swal({
          html: '*Please select the delivery address first',
          icon: 'warning',
          confirmButtonText: this.$t('close')
        }).then(result => {
          if (result.isConfirmed) {
            this.goBackAndSave();
          }
        });
      }
    },
    goBackAndSave() {
      this.saveFirstDialog = false;
      this.currentTab = 'tab-shipment';
    },
    saveRequired() {
      console.log(
        'shipment.saveRequired ? isNew:',
        this.isNew,
        ', item:',
        this.item,
        ', isFormDirty():',
        this.$refs.form.isFormDirty()
      );
      return this.isNew || this.$refs.form.isFormDirty();
    },
    onSave(shipment) {
      this.item = Object.assign({}, shipment);
    }
  }
};
</script>

<style lang="sass">
.group
  font-size: 15px
  font-weight: 500
</style>
