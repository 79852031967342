<template>
  <div class="Shipments">
    <template>
      <v-card elevation="0" class="mx-2">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-tabs v-model="currentTab" fixed-tabs>
          <v-tab
            v-for="item in tabs"
            :key="item.key"
            :href="'#tab-' + item.key"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item value="tab-active">
            <shipments-data-table :search="search" @edit="onEdit">
              <template #top>
                <v-toolbar flat>
                  <v-toolbar-title>{{
                    $t('shipments.Shipments')
                  }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical />
                  <v-spacer />
                  <v-btn
                    v-if="hasPermission('api.add_shipment')"
                    color="primary"
                    dark
                    class="mb-2 mr-2"
                    @click="onNewShipment"
                  >
                    {{ $t('shipments.new') }}
                    <v-icon right dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
            </shipments-data-table>
          </v-tab-item>
          <v-tab-item value="tab-archived">
            <shipments-data-table :search="search" archived @edit="onEdit">
              <template #top>
                <v-toolbar-title>{{
                  $t('shipments.Shipments')
                }}</v-toolbar-title>
              </template>
            </shipments-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    ShipmentsDataTable: () =>
      import('@/views/dashboard/components/shipment/shipmentsDataTable.vue')
  },
  props: {
    searchPhrase: {
      type: String,
      default: '',
      required: false
    }
  },
  data: function() {
    return {
      search: '',

      currentTab: 'tab-active'
    };
  },
  computed: {
    tabs() {
      return [
        { key: 'active', name: this.$t('active') },
        { key: 'archived', name: this.$t('archived') }
      ];
    }
  },
  methods: {
    onNewShipment() {
      this.$emit('new');
    },
    onEdit(item) {
      this.$emit('edit', item);
    }
  }
};
</script>
